
// Routes.js
import React from "react";
import { Route, Routes as ReactRoutes } from "react-router-dom"; // Rename the alias as ReactRoutes
import HomePage from "../Pages/HomePage";
import ManagePage from "../Pages/ManagePage";
import CalendarPage from "../Pages/CalendarPage";
import FaqPage from "../Pages/FaqPage";
import AdminPage from "../Pages/AdminPage";
import Signout from "../Pages/SignInOut";

const CustomRoutes = () => {
  return (
    <ReactRoutes> {/* Use the alias ReactRoutes */}
     <Route path="/signout" element={<Signout />} />
      <Route path="/" element={<HomePage />} />
      <Route path="/manage" element={<ManagePage />} />
      <Route path="/calendar" element={<CalendarPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/admin" element={<AdminPage />} />
    </ReactRoutes>
  );
};

export default CustomRoutes;
