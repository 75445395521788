import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import "./Styles/FaqPage.css";

class FaqPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [
        {
          question:
            "1.) Why is it important to clean email lists using Mailgun's Cleaner, and how often should you do it?",
          answer: `
            Cleaning email lists using Mailgun's Cleaner is essential for several reasons:
            1. It helps to reduce the risk of email spam.
            2. It helps to improve email deliverability.
            3. It helps to maintain a clean and engaged audience.
        
            It's recommended to clean your email list every month to ensure its quality.
          `,
        },
        {
          question: "2.) What do I name my files?",
          answer: `First, let's establish naming conventions for various elements in your database based on your provided statements:
        
        1. **Email Lists Naming Convention:**
           - Each email list should follow a consistent naming convention.
           - It's recommended to use a format like "Company-ListName" for naming email lists,
            ensuring that "Company" represents the company name,
             and "ListName" provides a descriptive name for the list.
        
        2. **City Naming Convention:**
           - When naming cities in your database, you can follow a format like "Company-City-Description."
           - Here, "Company" would be the name of your company,
            "City" is the name of the city, and "Description" can be any additional details if necessary.
            This helps in maintaining clear and organized records.
        
        3. **Template Naming Convention:**
           - For templates, use the format "Company-Date-ExtraNote" when naming them.
           - "Company" represents your company's name, 
           "Date" signifies the date the template was created or any relevant date,
            and "ExtraNote" is for additional information about the template.
        
        4. **Domains Naming Convention:**
           - Domains in your database should adhere to a format like "Name@Company.com."
           - Here, "Name" refers to the individual or entity associated with the email address,
            and "Company.com" represents your company's domain.

        5. **Email Tables Naming Convention:**
           - When dealing with significant email tables in your database queries,
            it's crucial to maintain clarity and consistency. Ensure that the primary
            email address field in these tables is uniformly labeled as "_email_address_".
            This standardization not only streamlines the querying process but also enhances 
            collaboration among team members. A standardized naming convention simplifies the 
            interpretation of data across various tables and promotes a cohesive understanding
            within your organization.
        
         By implementing these naming conventions, you'll ensure consistency and clarity
         in your database, making it easier to manage and search for specific records,
         email lists, cities, templates, and domains.
        It's important to communicate and document these conventions
         within your organization to maintain uniformity and make it easier 
         for team members to understand and work with the database.`,
        },

        {
          question: "3.) How to Schedule and Manage Email Campaigns in Pymail Blaster?",
          answer: `
            To schedule and manage your email campaigns effectively using Pymail Blaster, follow these steps:
        
            - Navigate to the "Scheduled Events" section within the Admin Page.
            - Click on "Schedule Email" to create a new scheduled event.
            - Select the desired date and time for the email campaign using the provided calendar and time picker.
            - Choose the client, domain, and template for the scheduled email from the respective dropdown menus.
            - Click on the "Schedule" button to confirm and set up the email campaign.
        
            By utilizing the scheduling feature, you can plan and automate your email campaigns,
            ensuring timely and targeted communication with your clients. Additionally, you can review 
            and manage all scheduled events in the \`Scheduled Events\` section to stay organized and 
            in control of your email marketing initiatives.
          `,
        },
        {
          question: "4.) Uploading a New Email List: Why is Debouncing a Critical Step?",
          answer: `
            Uploading a new email list is a crucial and potentially expensive process that demands meticulous
             attention. Debouncing, specifically performed by our dedicated development team under rigorous protocols,
             is an indispensable step in this procedure.
        
            **Why Debouncing Matters:**
            Uploading large email lists without proper debouncing can have severe consequences,
            potentially leading to the crash of our IP (Internet Protocol).
            Debouncing is the process of removeing unblocked, unsubscribed, spam traps
            and fake email adresses from our lists.
        
            **Why the Dev Team Handles Debouncing:**
            Our development team, led by Rich, oversees the debouncing process to guarantee
            the highest level of accuracy and efficiency. This step is not only a technical 
            requirement but also a strategic measure to safeguard the integrity of our email infrastructure.
        
            **Cost Implications:**
            The significance of debouncing is underscored by the associated costs.
            Mishandling this step may result in substantial financial implications,
            making it imperative for all new email list uploads to undergo this rigorous debouncing process.
        
            We emphasize the importance of adhering to this protocol to maintain the reliability 
            and performance of our email system. Your cooperation in following these procedures is not only 
            a procedural requirement but a fundamental commitment to the sustained success of our email campaigns.
          `,
        },
        
        // Add more questions and answers here
      ],
    };
  }

  render() {
    return (
      <div id="faqsPage">
        <h1 className="faq-title">FAQ Page</h1>
        <ul className="faq-list">
          {this.state.questions.map((qa, index) => (
            <li key={index}>
              <a href={`#question-${index}`}>{qa.question}</a>
            </li>
          ))}
        </ul>
        <div className="faq-QA">
          {this.state.questions.map((qa, index) => (
            <div key={index} id={`question-${index}`}>
              <h2>{qa.question}</h2>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <ReactMarkdown>{qa.answer}</ReactMarkdown>
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default FaqPage;

//[] Send Amanda  the urls
//Amanda said she will be making instructions can copy paste here
//and make a pretty page.
//Notes to pass on: [past article link here]
// Clean lists often to Maintain a good sending IP (weekly/Monthly)
//Notes about limitations and benefits
