import { getStealthHeaders } from "../../utils/getStealthHeaders";

function CreationForm({ method, action, onSend, onSuccess, onFailure, children })
{
  // If no failure routine is given, do nothing
  if (!onFailure) {
    onFailure = () => {};
  }

  // Submit the request and handle success/failure
  const onSubmit = async e => {
    e.preventDefault();
    if (onSend) {
      onSend();
    }
    fetch(action, {
      method,
      headers: await getStealthHeaders(),
      body: new FormData(e.target)
    }).then(response => response.json()).then(onSuccess).catch(onFailure);
  };

  return (
    <form method={method} action={action} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

export default CreationForm;
