import { Auth } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoginCard from "../assets/LoginCard.jpg";
import CoastTechLogo from '../assets/CoastTechLogo.png'
import "./Styles/SignInOut.css";

const SignInOut = () => { 
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  async function handleSignIn(event) {
    event.preventDefault(); // Prevent default form submission

    try {
      await Auth.signIn(username, password);
      navigate("/"); // Redirect to the Home page
    } catch (error) {
      console.error("Error signing in:", error);
    }
  }

  async function handleSignOut() {
    try {
      await Auth.signOut();
      navigate("/"); // Redirect to the Home page
    } catch (error) {
      console.error("Error signing out:", error);
    }
  }
  // async function handleForgotPassword() {
  //   navigate("/forgot-password"); 
  // }
  // In the Future can redirect to a page so you can verify and reset your self 
  const handleForgotPassword = () => {
    // Use window.alert() to display a message
    window.alert("Please contact a Digital Developer");
  }

  return (
    <div id="loginCardContainer">
      <div className="login-card">
        <div className="coastLogo">
          <div className="CoastLogo">
          <img src={CoastTechLogo} alt="Coastal Technology C Logo" id='CoastC' />
          </div>
        </div>
        <form className="login-form" onSubmit={handleSignIn}>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Enter your username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <button type="submit">Login</button>
          <button onClick={handleForgotPassword}>Forgot Password</button>
          <button onClick={handleSignOut}>Sign Out</button>
        </form>
      </div>
      <div className="img-container">
      <img src={LoginCard} alt="Coastal Scenery, Login Card Background Image" id='bgImg'/>
      </div>
    </div>
  );
};

export default SignInOut;