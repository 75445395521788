import { useState, useEffect } from "react";

const defaultOption = {
  id: "",
  name: "Select a client"
}

function ClientUpdateSelect({ clients, value, onChange })
{
  const [options, setOptions] = useState([defaultOption]);

  useEffect(() => {
    setOptions([defaultOption, ...clients]);
  }, [clients]);

  return (
    <select name="clientId" required value={value} onChange={onChange} style={{fontSize:'inherit', borderRadius: 'inherit'}}>
      {
        options.map(option => {
          const optionValue = option.id;
          const name = option.name;
          return (
            <option key={optionValue} value={optionValue}>{name}</option>
          );
        })
      }
    </select>
  )
}

export default ClientUpdateSelect;
