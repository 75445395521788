import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import "./App.css";
import SidebarMenu from "./Pages/Sidebar";
import Routes from "./Routes/Routes"; // Import the Routes component
import { EventProvider } from './Pages/MailEvents';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoutButton from "./Components/Buttons/LogOutButton";
import CoastTechLogo from './assets/CoastTechLogo.png'

const App = () => {
  return (
    <EventProvider>
      <ToastContainer />
      <Router>
        <div className="App">
          <div className="title1">Dealership Toolkit</div>
          <div id="LogoutButton">
            <LogoutButton />
          </div>
          <div className="main-doc">
            <SidebarMenu />
            <Routes />
            <div></div>
          </div>
        </div>
      </Router>
    </EventProvider>
  );
};

export default withAuthenticator(App, {
  hideSignUp: true,
  components: {
    SignIn: {
      Header() {
        return (
          <div className="coastLogo">
            <img src={CoastTechLogo} alt="Coastal Technology C Logo" id='CoastC' />
            <h1 className="welcome">Welcome to Stealth Email!</h1>
          </div>
        );
      }
    }
  }
});