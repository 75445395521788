import { getStealthHeaders } from "../../utils/getStealthHeaders";

function ManagementForm({ children })
{
  // Submit the request and handle success/failure
  const onSubmit = async e => {
    const body = new FormData(e.target);
    const mailingListId = body.get("mailingListId");
    e.preventDefault();
    fetch(`/api/lists/${mailingListId}/scans/`, {
      method: "POST",
      headers: await getStealthHeaders(),
      body
    }).then(response => response.json()).then(async responseBody => {
      const { dupeCount, uniqueCount } = responseBody;
      const message = `This operation will replace ${dupeCount.toLocaleString()} email address${dupeCount === 1 ? "" : "es"} and add ${uniqueCount.toLocaleString()} new one${uniqueCount === 1 ? "" : "s"}. Are you sure you want to continue?`;
      if (window.confirm(message)) {
        console.log("ORIGINAL FORM BODY", body);
        fetch(`/api/lists/${mailingListId}/updates/`, {
          method: "POST",
          headers: await getStealthHeaders(),
          body
        }).then(() => {
          alert("The mailing list was updated successfully.");
        })
      }
    }).catch(() => {
      alert("The mailing list is in the wrong format. Please ensure that it has “email_address”, “first_name”, and “last_name” columns.")
    });
  };

  return (
    <form onSubmit={onSubmit}>
      {children}
    </form>
  )
}

export default ManagementForm;
