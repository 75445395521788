import { isAdmin } from "../utils/isAdmin";
import React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,        
  faCalendar,    
  faMegaphone,
  faQuestionCircle,
  faUserShield,          
  faRightFromBracket
} from "@fortawesome/pro-solid-svg-icons";

import "./Styles/Sidebar.css";

const SidebarMenu = () => {
  const location = useLocation();
  const [showAdminLink, setShowAdminLink] = useState(false);

  useEffect(() => {
    (async () => {
      setShowAdminLink(await isAdmin());
    })();
  }, [])

  return (
    <div>
      <Menu>
        <Link to="/" className={location.pathname === "/" ? "active" : ""}>
          <FontAwesomeIcon icon={faHouse} className="fa-icon" />
          Home
        </Link>
        <Link
          to="/manage"
          className={location.pathname === "/manage" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faMegaphone} className="fa-icon" />
          Manage Ads
        </Link>
        <Link
          to="/calendar"
          className={location.pathname === "/calendar" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faCalendar} className="fa-icon" />
          Calendar
        </Link>
        <Link
          to="/faq"
          className={location.pathname === "/faq" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faQuestionCircle} className="fa-icon" />
          FAQ
        </Link>
        {showAdminLink ? (
          <Link
            to="/admin"
            className={location.pathname === "/admin" ? "active" : " "}
          >
            <FontAwesomeIcon icon={faUserShield} className="fa-icon" />
            Administrator
          </Link>
        ) : null}
        <Link
          to="/signout"
          className={location.pathname === "/signout" ? "active" : ""}
        >
          <FontAwesomeIcon icon={faRightFromBracket} className="fa-icon" />
          Sign In/Out
        </Link>
      </Menu>
    </div>
  );
};

export default SidebarMenu;
