import { getStealthHeaders } from "../../utils/getStealthHeaders";

function DeletionForm({ noun, name, onSuccess, onFailure, children })
{
  // Submit the request and handle success/failure
  const onSubmit = async e => {
    const body = new FormData(e.target);
    const id = body.get(name);
    e.preventDefault();
    if (window.confirm(`Are you sure you want to delete this ${noun}?`)) {
      fetch(`/api/${noun}s/${id}/`, {
        method: "DELETE",
        headers: await getStealthHeaders()
      }).then(response => response.json()).then(onSuccess).catch(onFailure);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      {children}
    </form>
  )
}

export default DeletionForm;
