import { getStealthHeaders } from "../../utils/getStealthHeaders";
import { useState, useEffect } from "react";

function ClientResourceSelect({ name, noun, clientId })
{
  const [options, setOptions] = useState([]);


  useEffect(() => {
    (async () => {
      const defaultOption = { id: "", name: `Select a ${noun}` };
      const url = `/api/${noun}s/?clientId=${clientId}`;
      if (clientId) {
        const response = await fetch(url, {
          headers: await getStealthHeaders()
        })
          .then(response => response.json())
          .catch(() => []);
        setOptions([defaultOption, ...response]);
      } else {
        setOptions([defaultOption]);
      }
    })();
  }, [clientId, noun]);

  return (
    <select name={name} required style={{fontSize:'inherit', borderRadius: 'inherit'}}>
      {
        options.map(option => {
          const value = option.id;
          const name = option.name;
          return (
            <option key={value} value={value}>{name}</option>
          );
        })
      }
    </select>
  )
}

export default ClientResourceSelect;
