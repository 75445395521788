import React from 'react';

const EmailPerformanceOverview = ({ startDate, endDate }) => {
  const startDateObject = new Date(startDate);
  const endDateObject = new Date(endDate);

  // Format the start and end dates
  const formattedStartDate = startDateObject.toLocaleDateString('en-US', { month: 'short', day: 'numeric', timeZone: 'UTC' });
  const formattedEndDate = endDateObject.toLocaleDateString('en-US', { month: 'short', day: 'numeric', timeZone: 'UTC' });

  // Create the title
  const title = `Email Overview (${formattedStartDate} - ${formattedEndDate})`;

  return (
    <h3>{title}</h3>
  );
};

export default EmailPerformanceOverview;
