import React, { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import './LogOutButton.css' ;

const LogoutButton = () => {
  const [loading, setLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(true);

  const handleLogout = async () => {
    setLoading(true);
//fill in the blank Fun!
try {
    await Auth.signOut(); // Use Auth.signOut() instead of creating a new CognitoAuth instance

    setLoading(false);
    setIsLoggedIn(false); // Update the isLoggedIn state to reflect the logout status
  } catch (error) {
    console.error(error);
    setLoading(false);
  }
};

  return (
    <button className ='buttons' onClick={handleLogout} disabled={!isLoggedIn}>
      {isLoggedIn ? 'Log Out' : 'Sign In'}
    </button>
  );
};

export default LogoutButton;


