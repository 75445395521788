import { useState, useEffect } from "react";

const defaultOption = {
  id: "",
  name: "Select a client"
}

function ClientSelect({ clients, value, onChange })
{
  const [options, setOptions] = useState([defaultOption]);

  useEffect(() => {
    setOptions([defaultOption, ...clients]);
  }, [clients]);

  return (
    <select name="clientId" required onChange={onChange} style={{fontSize:'inherit', borderRadius: 'inherit'}}>
      {
        options.map(option => {
          const value = option.id;
          const name = option.name;
          return (
            <option key={value} value={value}>{name}</option>
          );
        })
      }
    </select>
  )
}

export default ClientSelect;
