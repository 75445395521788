/*
import React, { useState, useEffect, useRef } from "react";
import { API, Auth } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "./Styles/ManagePage.css"
import PythonMail from "../assets/PythonMail.png"
import { useHistory } from 'react-router-dom';
import { useEventContext } from './MailEvents';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faJetFighterUp,
  faToilet,
  faGrid,
  faSunDust,
  famMailChimp,
} from "@fortawesome/pro-solid-svg-icons";

// import { useEventContext } from './EventContext';

// Sends/Scheduals Emails 

function ManagePage() {
  const thumb = PythonMail;
  const [emails, setEmails] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedOption1, setSelectedOption1] = useState("defaultOption1");
  const [selectedOption2, setSelectedOption2] = useState("defaultOption2");
  const [selectedOption3, setSelectedOption3] = useState(thumb);
  const [selectedOption4, setSelectedOption4] = useState("defaultOption4");
  const [thumbnail_urls, setThumbnail_urls] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [emailPrefix, setEmailPrefix] = useState("");
  const [mailGunInfo, setMailGunInfo] = useState([]);
  const [hasPermissions, setHasPermissions] = useState(false);

  const audioRef = useRef(null);
  const [data5, setData5] = useState([]);
  const [stats, setStats] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [nameInput, setNameInput] = useState("");
  // const { scheduleEvent, events } = useEventContext();
  const { events, addEvent, deleteEvent } = useEventContext();
  const [scheduledEvents, setScheduledEvents] = useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');

  const handleSelectChange = (event) => {
    setSelectedCompanyName(event.target.value);
  };

  const CompanyNames = [
    'Tacoma RV',
    'Specialty RV Sales',
    'RV Country',
    'Arizona RV Service',
    'Browns RV Superstore',
    'Colton RV',
    'Gerzenys RV World',
    'Great American RV',
    'Holman RV',
    'HWH RV',
    'Lerch RV',
    'Modern Coach Works',
    'Rchill Honda',
    'Oasis Eating Recovery',
  ];
  
  
  const handleDateTimeChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const centralTimeOffset = -5 * 60; // Central Time offset in minutes
    const centralTime = new Date(
      selectedDate.getTime() + centralTimeOffset * 60 * 1000
    );
    const formattedTime = centralTime
      .toISOString()
      .slice(0, -8)
      .replace("T", " ");
    setScheduledTime(formattedTime);
  };

  const scheduleEvent = (newEvent) => {
    addEvent(newEvent);
  };

  const removeEvent = (eventToDelete) => {
    deleteEvent(eventToDelete);
  };

  const domains = [
    "@tacomarv.info",
    "@specialtyrvsales.info",
    "@rvcountry.info",
    "@arizonarvservice.info",
    "@brownsrvsuperstore.info",
    "@coltonrv.social",
    "@gerzenysrvworld.info",
    "@greatamericanrv.com",
    "@greatamericanrv.info",
    "@holmanrv.info",
    "@hwhrv.info",
    "@lerchrv.info",
    "@moderncoachworks.info",
    "@rchillhonda.info",
    "@oasiseatingrecovery.info",
    "@budgetrv.social",
    "@giantrv.social",
    "@collierrv.com"
  ];

  const testData = [
    { name: "Bounces", val: 10 },
    { name: "Clicks", val: 20 },
    { name: "Drops", val: 30 },
  ];

  useEffect(() => {

    document.addEventListener("click", null); //If I removed playAudio the screen turns red, So now it is null.
    // fetchGroupUnsubscribeEvents();
    callApi2();
    // callApi3();
    // callApi4();
    // callApi5();
    return () => {
      document.removeEventListener("click", null);
    };
  }, []);

  async function checkUserGroupMembership() {
    try {
      const user = await Auth.currentAuthenticatedUser();
  
      // The user object will contain information about the user's attributes, including groups.
      console.log('User Groups:', user.signInUserSession.accessToken.payload['cognito:groups']);
  
      // Replace 'your-group-name' with the actual group you want to check.
      if (user.signInUserSession.accessToken.payload['cognito:groups'].includes('Admin')) {
        console.log('User is in the specified group.');
      } else {
        console.log('User is not in the specified group.');
      }
    } catch (error) {
      console.error('Error checking group membership:', error);
    }
  }
  
  checkUserGroupMembership();


  // async function fetchGroupUnsubscribeEvents() {
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     const token = user.signInUserSession.idToken.jwtToken;

  //     const requestInfo = {
  //       headers: {
  //         Authorization: token,
  //       },
  //     };

  //     const data = await API.get(
  //       "pyguyApi",
  //       "/dtk-emailer-filter-webhook",
  //       requestInfo
  //     );
  //     console.log("API Response:", data);

  //     if (data && Array.isArray(data)) {
  //       setDropdownOptions(data);
  //     }
  //   } catch (error) {
  //     console.log("Error calling API:", error);
  //   }
  //   // console.log(dropdownOptions);
  // }

  async function callApi() {
    try {
      setIsButtonDisabled(true);

      if (scheduledTime) {
        const scheduledTimestamp = new Date(scheduledTime).getTime();
        const currentTime = new Date().getTime();
        const delay = scheduledTimestamp - currentTime;

        if (delay > 0) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
      }

      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      
      const requestInfo = {
        headers: {
          Authorization: token,
          template_id: selectedOption2,
          table_name: selectedOption1,
          domain: selectedOption4,
          email_prefix: emailPrefix
        },
      };

      const data = await API.post("pyguyApi", "/pyguyapi", requestInfo);
      console.log("API Response:", data);

      if (data && Array.isArray(data.Emails)) {
        setEmails(data.Emails);
        const templateId = data.TemplateId || "";
        setTemplateId(templateId);

        console.log("Template ID:", templateId);
      } else {
        setEmails([]);
        setTemplateId("");
      }

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 30000);
    } catch (error) {
      console.log("Error calling API:", error);
    }
  }

  async function callApi2() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      const requestInfo = {
        headers: {
          Authorization: token,
        },
      };

      const data = await API.get(
        "pyguyApi",
        "/get-BQ-Tables",
        requestInfo
      );
      console.log("API Response:", data);

      if (data && Array.isArray(data)) {
        setDropdownOptions(data);
      }
    } catch (error) {
      console.log("Error calling API:", error);
    }
    console.log(dropdownOptions);
  }

  async function callApi3(domain) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      const requestInfo = {
        headers: {
          Authorization: token,
        },
        queryStringParameters: {
          domain: domain.slice(1)
        }
      };

      const data = await API.get(
        "pyguyApi",
        "/list-mailgun-templates",
        requestInfo
      );
      console.log("This is the droid we are looking for", data);
      console.log(data)
      // const mapped = data.items.map((o) => o.name);
      // console.log(mapped);
      // const thumbnails = data.map((o) => o.thumbnail_url);
      // console.log(thumbnails);
      console.log(data)

      if (data) {
        // const keys = Object.keys(data[0]);
        setMailGunInfo(data.items);
        // setThumbnail_urls(thumbnails);

        // console.log(data);
      } else {
        setMailGunInfo([]); // No data or empty array, reset sendgridInfo state
        // setThumbnail_urls([]);

      }
    } catch (error) {
      console.log("Error calling API:", error);
    }
  }

  // async function callApi4() {
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     const token = user.signInUserSession.idToken.jwtToken;

  //     const requestInfo = {
  //       headers: {
  //         Authorization: token,
  //       },
  //     };

  //     const data = await API.get(
  //       "pyguyApi",
  //       "/getsendgridstats",
  //       requestInfo
  //     );
  //     console.log("API Response", data);

  //     if (data && Array.isArray(data)) {
  //       setStats(data);
  //     } else {
  //       setStats([]);
  //     }
  //   } catch (err) {
  //     console.log("Error calling API:", err);
  //   }
  // }

  // async function callApi5() {
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     const token = user.signInUserSession.idToken.jwtToken;

  //     const requestInfo = {
  //       body: [
  //         { asm_group_id: 22369 },
  //         { email: "example@example.com" },
  //         { event: "clicked" },
  //       ],
  //       headers: {
  //         Authorization: token,
  //       },
  //     };

  //     const data = await API.post("pyguyApi", "/hook", requestInfo);
  //     console.log("API Response", data);

  //     if (data && Array.isArray(data)) {
  //       setData5(data);
  //     } else {
  //       setData5([]);
  //     }
  //   } catch (err) {
  //     console.log("Error calling API:", err);
  //   }
  // }
  return (

    <div className="App">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const newEvent = {
            title: 'Scheduled Event',
            start: moment(
              `${moment(selectedDate).format('YYYY-MM-DD')}T${selectedTime}`
            ).toDate(),
            city: selectedOption1,
            template: selectedOption2,
            name: nameInput,
          };
          scheduleEvent(newEvent); // Use the context function to add events
        }}
      ></form>
        <div className="content-container">
          <div className="content">
            <div className="main-doc">
              <div className="scheduler-container">
                {console.log(scheduledTime)}
                <input
                  type="datetime-local"
                  value={scheduledTime || ""}
                  onChange={handleDateTimeChange}
                />
              </div>
            </div>
            <div id="sentenceNInputs" className="centered-container">
            <div className="CompanyNames">
              <div>
      <label htmlFor="dropdown">Select an option:</label>
      <select id="dropdown" value={selectedCompanyName} onChange={handleSelectChange}>
        <option value="">Select an option</option>
        {CompanyNames.map((companyName, index) => (
          <option key={index} value={companyName}>
            {companyName}
          </option>
        ))}
      </select>
     
    </div>
              <p>
                Put the first name for domain sender & select a domain
              </p>
              </div>
              <div className="pickNames">

                {templateId && <p>Template ID: {templateId}</p>}
                {dropdownOptions.length > 0 && (
                  <input
                    type="text"
                    placeholder="Pick a Name"
                    value={emailPrefix}
                    onChange={(e) => setEmailPrefix(e.target.value)}
                  />
                )}
                <div className="domains">
                  <select
                    value={selectedOption4}
                    onChange={(e) => {
                      callApi3(e.target.value);
                      setSelectedOption4(e.target.value)}}
                  >
                    <option value="defaultOption4">@DealershipName.com</option>
                    {domains.map((domain, index) => (
                      <option key={index} value={domain}>
                        {domain}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div>
              
                <div>

                  <div className="product-image">
                    <img src={selectedOption3 || PythonMail} className="PythonsMail" alt="Thumbnail" />
                    <div style={{ marginBottom: '20px' }}>
                      <select
                        value={selectedOption1}
                        onChange={(e) => setSelectedOption1(e.target.value)}
                      >
                        <option value="defaultOption1">Select a city</option>
                        {dropdownOptions.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <div className="cityPadding">
                      </div>
                      <div className="template">
                        <select
                          value={selectedOption2}
                          onChange={(e) => {
                            const value = e.target.value;
                            setSelectedOption2(value);
                            const url = e.target.querySelector(`[value="${value}"]`)
                         
                            setSelectedOption3(url);
                          }}
                        >
                          <option value="defaultOption2">Select a template</option>
                          {mailGunInfo.map((option, index) => (
                            <option
                              key={index}
                              value={option.template_id}
                              data-url={option.thumbnail_url}
                            >
                              {option.name}
                            </option>
                          ))}
                        </select>

                      </div>
                      <div id='makeItSo'>
                        <button
                          className={`action-button ${isButtonDisabled ? "disabled-button" : ""
                            }`}
                          onClick={callApi}
                          disabled={isButtonDisabled}
                        >
                          {scheduledTime ? `Send at ${scheduledTime}` : "Make it so"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
             
              </div>
            </div>
          </div>
        </div>
     )}  


export default ManagePage;
*/

import { getStealthHeaders } from "../utils/getStealthHeaders";
import React, { useState, useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import { fetchEventsFromBackend,fetchTemplatesFromBackend } from "../ApiCalls/FetchEventsFromBE";
import './Styles/ManagePage.css';
import CreationForm from "../Components/FormComponents/CreationForm";
import ClientSelect from "../Components/FormComponents/ClientSelect";
import ClientResourceSelect from "../Components/FormComponents/ClientResourceSelect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import {
  faCheck,
} from "@fortawesome/pro-solid-svg-icons";   

const Section = ({ name, title, children }) => {
  return (
    <div style={{ marginTop: '20px',
     border: '1px solid #00000033',
      borderRadius: '10px',
       padding: '10px',
      backgroundColor: '#e8f0f8',
      boxShadow: '5px 5px 3px 0 #000000aa',
      boxSizing: 'border-box',
      display: 'inline-block',
      minWidth: '36ch',
      flexDirection: 'column',
      alignItems: 'center',
       textAlign: 'center' }} className={`section ${name}`}>
      <h2>{title}</h2>
      {children}
    </div>
  );
}

const GridContainer = ({ children }) => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      /*
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', // Adjust the minimum and maximum width
      gap: '20px',
      padding: '20px',
      */
    }}>
      {children}
    </div>
  );
}

function convertUnixToCST(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert the timestamp to milliseconds
  date.setHours(date.getHours() - 5); // Convert UTC to CST (UTC-5)

  const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

  const dateString = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
  const timeString = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

  return `${dateString} ${timeString}`;
}


const ManagePage = () => {
  const [templates, setTemplates] = useState([]);
  const [validationResult, setValidationResult] = useState(null);
  const [templateToDelete, setTemplateToDelete] = useState(-1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [events, setEvents] = useState([]); 
  //const [eventData ,setEventsData] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState("");
  const [isScheduled, setIsScheduled] = useState(false);
  const [sendTime, setSendTime] = useState("");
  const [uploadEmailTemplateMessage, setUploadEmailTemplateMessage] = useState("");
  const [sendEmailMessage, setSendEmailMessage] = useState("");
  const [sendButtonIsDisabled, setSendButtonIsDisabled] = useState(false);

  useEffect(() => {
    (async () => {
      const nextClients = await fetch("/api/clients/", {
        headers: await getStealthHeaders()
      }).then(response => response.json()).catch(() => []);
      setClients(nextClients);
      const nextEvents = await fetch("/api/events/", {
        headers: await getStealthHeaders()
      }).then(response => response.json()).catch(() => []);
      setEvents(nextEvents);
    })();
  }, [uploadEmailTemplateMessage]);

  /*
  useEffect(() => {
    // Fetch events and templates when the component mounts
    fetchEventsFromBackend()
      .then((events) => setEventsData(events))
      .catch((error) => console.error('Error fetching events:', error));

    fetchTemplatesFromBackend()
      .then((templates) => setTemplates(templates))
      .catch((error) => console.error('Error fetching templates:', error));
  }, []);
  */

  const validateHTML = (htmlContent) => {
    // Sanitize the HTML content to prevent XSS
    const sanitizedHTML = DOMPurify.sanitize(htmlContent);

    // Check for the presence of required elements
    const headerPresent = /<header>/i.test(sanitizedHTML);
    const addressPresent = /<address>/i.test(sanitizedHTML);
    const privacyPolicyPresent = /<a href".*privacy policy.*">/i.test(sanitizedHTML);
    const unsubscribePlaceholderPresent = /{{{unsubscribe}}}/.test(sanitizedHTML);
    const unsubscribeLinkWorks = /<a[^>]*href=".*?">.*?<\/a>/i.test(sanitizedHTML);

    const isValid = headerPresent && addressPresent && privacyPolicyPresent && unsubscribePlaceholderPresent && unsubscribeLinkWorks;

    return {
      isValid,
      headerPresent,
      addressPresent,
      privacyPolicyPresent,
      unsubscribePlaceholderPresent,
      unsubscribeLinkWorks,
    };
  };

  // Function to handle file upload and validation

  const handleFileUpload = (file) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const htmlContent = e.target.result;
        const validationResults = validateHTML(htmlContent);

        // Set the validation result in state
        setValidationResult(validationResults);
      };

      reader.readAsText(file);
    }
  };

  // Function to handle file upload and validation

  const handleAddTemplate = () => {
    const newTemplateName = prompt("Enter the template name: Date-Company-Notes"); // Prompt the user for the template name
    if (newTemplateName) {
      // Add the new template to the list of templates
      setTemplates([...templates, newTemplateName]);
    }
  };

  const handleDeleteTemplate = (templateId) => {
    // Remove the template with the specified ID
    const updatedTemplates = templates.filter((template, index) => index !== templateId);
    setTemplates(updatedTemplates);
  };

  // Function to show the confirmation dialog for template deletion
  const showDeleteConfirmation = (index) => {
    setTemplateToDelete(index);
    setShowConfirmation(true);
  };

  // Function to cancel the template deletion
  const cancelDelete = () => {
    setTemplateToDelete(-1);
    setShowConfirmation(false);
  };

  // Function to confirm and delete the template
  const confirmDelete = () => {
    if (templateToDelete >= 0) {
      const updatedTemplates = templates.filter((_, index) => index !== templateToDelete);
      setTemplates(updatedTemplates);
      setTemplateToDelete(-1);
      setShowConfirmation(false);
    }
  };

  const eventsData = [
    { label: 'A Scheduled Event', timestamp: 1672226820, sent: true },
    { label: 'A Scheduled Event 2', timestamp: 1672314660, sent: false },
    { label: 'A Scheduled Event 3', timestamp: 1672410480, sent: false }
  ];

  return (
    <div id="adminContainer">
      <h1>Management Page</h1>

      <div style={{
  display: 'flex',
  justifyContent: 'center',
   alignItems: 'center'
}}>
  <GridContainer>
        <Section name="emailtemplate" title="Upload Template">
          <CreationForm
            method="POST"
            action="/api/templates/"
            onSuccess={
              body => {
                const { name } = body;
                alert(`Template “${name}” created successfully!`);
                setUploadEmailTemplateMessage(`Uploaded “${name}”`);
              }
            }
            onFailure={
              () => {
                alert("The template is not in the right format. Please ensure that it has an “%unsubscribe_url%” link.");
              }
            }
          >
            <label>Name</label><br /><br />
            <input name="name" placeholder={"Email Template Name"} required /><br /><br />
            <label>Subject Line</label><br /><br />
            <input name="subject" placeholder={"Subject Line"} required /><br /><br />
            <label>Choose a client: </label>
            <ClientSelect clients={clients} />
            <br /><br />
            <label>Choose an HTML file:</label><br /><br />
            <input name="file" type="file" accept="text/html" required /><br /><br />
            <button>Upload</button>
          </CreationForm>
          <p>
            {uploadEmailTemplateMessage && (
              <>
                <FontAwesomeIcon icon={faCheck} />{" "}
                <span>{uploadEmailTemplateMessage}</span>
              </>
            )}
          </p>
  {/*
  <label className="uploaderText"> Upload Html Templates </label>
  <form method="POST" action="/templates" id="uploadForm" encType="multipart/form-data" className="htmlUploader" >
    <input type="file" name="htmlFile" id="htmlFile" accept=".html" />
    <button className="SubmitButton" type="submit">Submit</button>
    {validationResult && (
      <div>
        <h3>HTML Validation Result:</h3>
        <p>Header Present: {validationResult.headerPresent ? "Yes" : "No"}</p>
        <p>Address Present: {validationResult.addressPresent ? "Yes" : "No"}</p>
        <p>Privacy Policy Present: {validationResult.privacyPolicyPresent ? "Yes" : "No"}</p>
        <p>Unsubscribe Placeholder Present: {validationResult.unsubscribePlaceholderPresent ? "Yes" : "No"}</p>
        <p>Unsubscribe Link Works: {validationResult.unsubscribeLinkWorks ? "Yes" : "No"}</p>
        <p>Overall Valid: {validationResult.isValid ? "Yes" : "No"}</p>
      </div>
    )}
  </form>
  <div id="viewWindow"></div>
  <h2>Templates Available: </h2>
  {templates && templates.length > 0 ? (
  templates.map((template, index) => (
    <div key={index}>
      <p>{template}</p>
      <button onClick={() => showDeleteConfirmation(index)}>Delete</button>
    </div>
  ))
) : (
  <p>No templates available.</p>
)}

  {showConfirmation && (
    <div className="confirmation-dialog">
      <p>Are you sure you want to delete this template?</p>
      <button onClick={cancelDelete}>No</button>
      <button onClick={confirmDelete}>Yes</button>
    </div>
  )}
  */}
        </Section>


        <Section
          name="scheduleemail"
          title="Send and Schedule Email"
        >
          <CreationForm
            method="POST"
            action="/api/messages/"
            onSend={() => {
              setSendButtonIsDisabled(true);
            }}
            onSuccess={
              body => {
                const handled = isScheduled ? "scheduled" : "sent";
                alert(`Your emails were ${handled} successfully!`);
                setSendEmailMessage(`Emails ${handled} successfully`);
                setSendButtonIsDisabled(false);
              }
            }
            onFailure={
              () => {
                alert("Your emails failed to send. Please ensure that the domain is verified through Mailgun.")
              }
            }
          >
            {/*
            <label>Name</label><br /><br />
            <input name="name" placeholder={"Scheduled Email Name"} required />
            */}
            <br /><br />
            <input
              id="emailSenderIsScheduled"
              name="isScheduled"
              type="checkbox"
              value={isScheduled}
              onChange={
                e => {
                  setIsScheduled(e.target.checked);
                }
              }
            />&nbsp;
            <label for="emailSenderIsScheduled">Send the emails at a particular time</label>
            <br /><br />
            <input
              name="localSendTime"
              type="datetime-local"
              disabled={!isScheduled}
              onChange={
                e => {
                  try {
                    setSendTime(new Date(e.target.value).toISOString().slice(0, -1));
                  } catch (e) {
                    setSendTime("");
                  }
                }
              }
            />
            <input name="sendTime" type="text" value={sendTime} hidden />
            <br /><br />
            <label>Choose a client: </label>
            <ClientSelect
              clients={clients}
              onChange={
                e => {
                  setClientId(e.target.value);
                }
              }
            />
            <br /><br />
            <label>Choose a domain: </label>
            <ClientResourceSelect
              name="domainId"
              noun="domain"
              clientId={clientId}
            />
            <br /><br />
            <label>Choose a list: </label>
            <ClientResourceSelect
              name="mailingListId"
              noun="list"
              clientId={clientId}
            />
            <br /><br />
            <label>Choose a template: </label>
            <ClientResourceSelect
              name="emailTemplateId"
              noun="template"
              clientId={clientId}
            />
            <br /><br />
            <button
              disabled={sendButtonIsDisabled}
            >
              {isScheduled ? "Schedule" : "Send"}
            </button>
          </CreationForm>
          <p>
            {sendEmailMessage && (
              <>
                <FontAwesomeIcon icon={faCheck} />{" "}
                <span>{sendEmailMessage}</span>
              </>
            )}
          </p>
        </Section>

      <Section name="Events" title="Scheduled Events">
        <div>
          {events && events.length > 0 ? (
            <>
              <small>All times shown below are in Central Standard Time.</small>
              <ul>
                {
                  events.map((event) => {
                    const sendTime = new Date(`${event.send_time}Z`);
                    return (
                      <li key={event.id}>
                        {event.title},&nbsp;
                        {sendTime.toLocaleString()}
                      </li>
                    )
                  })
                }
              </ul>
            </>
          ) : (
            <p>No scheduled events available.</p>
          )}
        </div>
</Section>
</GridContainer>

</div>
    </div>
    
  )
  };

export default ManagePage;
