import { getStealthHeaders } from "../utils/getStealthHeaders";
import ClientSelect from "../Components/FormComponents/ClientSelect";
import ClientResourceUpdateSelect from "../Components/FormComponents/ClientResourceUpdateSelect";
import React, { useState, useEffect, useRef } from "react";
import { API, Auth } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import {
  faMessageSlash,
  faFaceEyesXmarks,
  faCheck,
  faQuestion,
  faBookBlank,
  faBookSkull,
  faComputerMouse,
  faPersonFallingBurst,
  faPersonToDoor,
  faEnvelopeCircleCheck,
  faBasketball,
  faMailboxFlagUp,
  faEnvelopeOpen,
  faEnvelopeBadge,
} from "@fortawesome/pro-solid-svg-icons";   
import "./Styles/HomePage.css"
import EmailPerformanceOverview from "../Components/EventModals/EmailPerformanceOverview";
import domtoimage from "dom-to-image";

async function createPdf()
{
  const formData = new FormData();
  const app = document.getElementById("responsive-table");
  const blob = await domtoimage.toBlob(app);
  formData.append("file", blob);
  const response = await fetch("/api/stats/pdf/", {
    method: "POST",
    headers: await getStealthHeaders(),
    body: formData
  });
  const pdf = await response.blob();
  const url = URL.createObjectURL(pdf);
  window.open(url, "_blank");
}

// Pulling Data and Analytics
function HomePage() {
  /*
  const [emails, setEmails] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [sendgridInfo, setSendgridInfo] = useState([]);
  const [selectedOption1, setSelectedOption1] = useState("defaultOption1");
  const [selectedOption2, setSelectedOption2] = useState("defaultOption2");
  const [selectedOption3, setSelectedOption3] = useState("defaultOption3");
  const [selectedOption4, setSelectedOption4] = useState("defaultOption4");
  const [thumbnail_urls, setThumbnail_urls] = useState([]);
  const [data1, setData1] = useState([]);
  const [stats, setStats] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [data5, setData5] = useState([]);
  const [emailPrefix, setEmailPrefix] = useState("");
  const audioRef = useRef(null);

  const domains = [
    "@tacomarv.info",
    "@specialtyrvsales.info",
    "@rvcountry.info",
    "@arizonarvservice.info",
    "@brownsrvsuperstore.info",
    "@coltonrv.social",
    "@gerzenysrvworld.info",
    "@greatamericanrv.com",
    "@greatamericanrv.info",
    "@holmanrv.info",
    "@hwhrv.info",
    "@lerchrv.info",
    "@moderncoachworks.info",
    "@rchillhonda.info",
    "@oasiseatingrecovery.info"
  ];

  const testData = [
    { name: "Bounces", val: 10 },
    { name: "Clicks", val: 20 },
    { name: "Drops", val: 30 },
  ];

  useEffect(() => {
    document.addEventListener("click", playAudio);
    fetchGroupUnsubscribeEvents();
    callApi2();
    callApi3();
    callApi4();
    callApi5();
    return () => {
      document.removeEventListener("click", playAudio);
    };
  }, []);

  function playAudio() {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.log("Error playing audio:", error);
      });
    }
  }

    async function fetchGroupUnsubscribeEvents() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const idToken = user.signInUserSession.idToken;
        const token = user.signInUserSession.idToken.jwtToken;
        const accessToken = user.signInUserSession.accessToken;
        console.log("ID TOKEN", accessToken.payload);
        console.log("GROUPS", accessToken.payload["cognito:groups"]);

        const requestInfo = {
          headers: {
            Authorization: token,
          },
        };

        const data = await API.get(
          "pyguyApi",
          "/dtk-emailer-filter-webhook",
          requestInfo
        );
        console.log("API Response:", data);

        if (data && Array.isArray(data)) {
          setDropdownOptions(data);
        }
      } catch (error) {
        console.log("Error calling API:", error);
      }
      // console.log(dropdownOptions);
  }

  async function callApi() {
    try {
      setIsButtonDisabled(true);

      if (scheduledTime) {
        const scheduledTimestamp = new Date(scheduledTime).getTime();
        const currentTime = new Date().getTime();
        const delay = scheduledTimestamp - currentTime;

        if (delay > 0) {
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
      }

      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      const requestInfo = {
        headers: {
          Authorization: token,
          template_id: selectedOption2,
          table_name: selectedOption1,
          domain: selectedOption4,
          email_prefix: emailPrefix
        },
      };

      const data = await API.post("pyguyApi", "/dtkEmailerLambda-dev", requestInfo);
      console.log("API Response:", data);

      if (data && Array.isArray(data.Emails)) {
        setEmails(data.Emails);
        const templateId = data.TemplateId || "";
        setTemplateId(templateId);

        console.log("Template ID:", templateId);
      } else {
        setEmails([]);
        setTemplateId("");
      }

      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 30000);
    } catch (error) {
      console.log("Error calling API:", error);
    }
  }

  async function callApi2() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      const requestInfo = {
        headers: {
          Authorization: token,
        },
      };

      const data = await API.get(
        "pyguyApi",
        "/get-BQ-Tables",
        requestInfo
      );
      console.log("API Response:", data);

      if (data && Array.isArray(data)) {
        setDropdownOptions(data);
      }
    } catch (error) {
      console.log("Error calling API:", error);
    }
    // console.log(dropdownOptions);
  }

  async function callApi3() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      const requestInfo = {
        headers: {
          Authorization: token,
        },
      };

      const data = await API.get(
        "dtkEmailerApi",
        "/getsendgridinfo",
        requestInfo
      );
      console.log("API Response", data);

      const mapped = data.map((o) => o.template_id);
// console.log(mapped);
      const thumbnails = data.map((o) => o.thumbnail_url);
      // console.log(thumbnails);
      // console.log(data)

      if (data && Array.isArray(data) && data.length > 0) {
        const keys = Object.keys(data[0]);
        setSendgridInfo(keys);
        setThumbnail_urls(thumbnails);
        setData1(data);
        console.log(data);
      } else {
        setSendgridInfo([]); // No data or empty array, reset sendgridInfo state
        setThumbnail_urls([]);
        setData1([]);
      }
    } catch (error) {
      console.log("Error calling API:", error);
    }
  }

  async function callApi4() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      const requestInfo = {
        headers: {
          Authorization: token,
        },
      };

      const data = await API.get(
        "pyguyApi",
        "/getsendgridstats",
        requestInfo
      );
      console.log("API Response", data);

      if (data && Array.isArray(data)) {
        setStats(data);
      } else {
        setStats([]);
      }
    } catch (err) {
      console.log("Error calling API:", err);
    }
  }

  async function callApi5() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      const requestInfo = {
        body: [
          { asm_group_id: 22369 },
          { email: "example@example.com" },
          { event: "clicked" },
        ],
        headers: {
          Authorization: token,
        },
      };

      const data = await API.post("pyguyApii", "/hook", requestInfo);
      console.log("API Response", data);

      if (data && Array.isArray(data)) {
        setData5(data);
      } else {
        setData5([]);
      }
    } catch (err) {
      console.log("Error calling API:", err);
    }
  }
  */

      const currentDateObject = new Date();
      const beginningDateObject = new Date();
      beginningDateObject.setDate(1);
      const currentDate = currentDateObject.toISOString().replace(/T.*/, "");
      const beginningDate = beginningDateObject.toISOString().replace(/T.*/, "");
      const [stats, setStats] = useState(null);
      const [clients, setClients] = useState([]);
      const [clientId, setClientId] = useState("");
      const [domainId, setDomainId] = useState("");
      const [startDate, setStartDate] = useState(beginningDate);
      const [endDate, setEndDate] = useState(currentDate);

      useEffect(() => {
        (async () => {
          const nextClients = await fetch("/api/clients/", {
            headers: await getStealthHeaders()
          }).then(response => response.json()).catch(() => []);
          setClients(nextClients);
        })();
      }, []);

      useEffect(() => {
        (async () => {
          setStats(null);
          if (domainId) {
            const startDateObject = new Date(startDate);
            const endDateObject = new Date(endDate);
            const start = Math.floor(+startDateObject / 1000);
            const end = Math.floor(+endDateObject / 1000);
            console.log("START", start);
            console.log("END", end);
            const body = await fetch(`/api/stats/${domainId}/?start=${start}&end=${end}`, {
              headers: await getStealthHeaders()
            }).then(response => response.json());
            setStats(body);
          }
        })();
      }, [domainId, startDate, endDate]);

  return (

    <div id="app" className="App">

      <div id="responsive-table">

        <div id="cell-layout" style={{ width: "60ch", height: "20em" }}>
      <form name="selector">
        <h1 style={{ textAlign: "center" }}>Monthly Statistics</h1>
        <p>
          <label>Choose a client: </label>
          <ClientSelect
            clients={clients}
            onChange={
              e => {
                setClientId(e.target.value);
              }
            }
          />
        </p>
        <p>
          <label>Choose a domain: </label>
          <ClientResourceUpdateSelect
            name="domainId"
            noun="domain"
            clientId={clientId}
            value={domainId}
            onChange={
              e => {
                setDomainId(e.target.value);
              }
            }
          />
        </p>
        <p>
          <label>Choose a start date:</label>
          {" "}
          <input type="date" name="startDate" value={startDate} onChange={e => { setStartDate(e.target.value) }} />
        </p>
        <p>
          <label>Choose an end date:</label>
          {" "}
          <input type="date" name="endDate" value={endDate} onChange={e => { setEndDate(e.target.value) }} />
        </p>
        <p>
          <button type="button" onClick={createPdf} disabled={stats === null}>Export to PDF</button>
        </p>
      </form>
        {domainId ? stats ? (
          <>
            <div className="EmailOverview">
              <EmailPerformanceOverview startDate={startDate} endDate={endDate} />
            </div>
            <table style={{ margin: "auto", width: "fit-content", whiteSpace: "nowrap" }}>
              <thead>
                <tr>
                  <th>Message</th>
                  <th>
                    <span>
                      <FontAwesomeIcon icon={faEnvelopeBadge} spin className="fa-icon" />{" "}
                      <span>Opens</span>
                    </span>
                  </th>
                  <th>
                    <span>
                      <FontAwesomeIcon icon={faComputerMouse} beat className="fa-icon" />{" "}
                      <span>Clicks</span>
                    </span>
                  </th>
                  <th>
                    <span>
                      <FontAwesomeIcon icon={faPersonToDoor} flip className="fa-icon" />{" "}
                      <span>Unsubscribes</span>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  stats.messages.map(message => (
                    <tr>
                      <td>{message["group_key"]}</td>
                      <td>{message.opened.total.toLocaleString()}</td>
                      <td>{message.clicked.total.toLocaleString()}</td>
                      <td>{message.unsubscribed.total.toLocaleString()}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </>
        ) : <p style={{ margin: "auto", width: "fit-content" }}>Loading statistics...</p> : null}
          </div>
      </div> 
    </div>

  )
}
;

export default HomePage;

