import { isAdmin } from "../utils/isAdmin";
import { getStealthHeaders } from "../utils/getStealthHeaders";
import React, { useState, useEffect, useRef } from "react";
import DOMPurify from "dompurify";
import { fetchEventsFromBackend,fetchTemplatesFromBackend } from "../ApiCalls/FetchEventsFromBE";
import './Styles/AdminPage.css';
import CreationForm from "../Components/FormComponents/CreationForm";
import ManagementForm from "../Components/FormComponents/ManagementForm";
import DeletionForm from "../Components/FormComponents/DeletionForm";
import ClientSelect from "../Components/FormComponents/ClientSelect";
import ClientResourceSelect from "../Components/FormComponents/ClientResourceSelect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import {
  faCheck,
} from "@fortawesome/pro-solid-svg-icons";   

const Section = ({ name, title, children }) => {
  return (
    <div style={{ marginTop: '20px',
     border: '1px solid #00000033',
      borderRadius: '10px',
       padding: '10px',
      backgroundColor: '#e8f0f8',
      boxShadow: '5px 5px 3px 0 #000000aa',
      boxSizing: 'border-box',
      display: 'inline-block',
      minWidth: '36ch',
      flexDirection: 'column',
      alignItems: 'center',
       textAlign: 'center' }} className={`section ${name}`}>
      <h2>{title}</h2>
      {children}
    </div>
  );
}

const GridContainer = ({ children }) => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      /*
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', // Adjust the minimum and maximum width
      gap: '20px',
      padding: '20px',
      */
    }}>
      {children}
    </div>
  );
}

function convertUnixToCST(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000); // Convert the timestamp to milliseconds
  date.setHours(date.getHours() - 5); // Convert UTC to CST (UTC-5)

  const dateOptions = { month: '2-digit', day: '2-digit', year: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

  const dateString = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
  const timeString = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

  return `${dateString} ${timeString}`;
}


const AdminPage = () => {
  const [templates, setTemplates] = useState([]);
  const [validationResult, setValidationResult] = useState(null);
  const [templateToDelete, setTemplateToDelete] = useState(-1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [events, setEvents] = useState([]); 
  //const [eventData ,setEventsData] = useState([]);
  const [clients, setClients] = useState([]);
  const [updateMailingListClientId, setUpdateMailingListClientId] = useState("");
  const [deleteMailingListClientId, setDeleteMailingListClientId] = useState("");
  const [deleteEmailTemplateClientId, setDeleteEmailTemplateClientId] = useState("");
  const [isScheduled, setIsScheduled] = useState(false);
  const [sendTime, setSendTime] = useState("");
  const [uploadMailingListMessage, setUploadMailingListMessage] = useState("");
  const [showAdminPage, setShowAdminPage] = useState(false);

  useEffect(() => {
    (async () => {
      setShowAdminPage(await isAdmin());
    })();
  }, [])

  useEffect(() => {
    (async () => {
      const nextClients = await fetch("/api/clients/", {
        headers: await getStealthHeaders()
      }).then(response => response.json()).catch(() => []);
      setClients(nextClients);
      const nextEvents = await fetch("/api/events/", {
        headers: await getStealthHeaders()
      }).then(response => response.json()).catch(() => []);
      setEvents(nextEvents);
    })();
  }, []);

  /*
  useEffect(() => {
    // Fetch events and templates when the component mounts
    fetchEventsFromBackend()
      .then((events) => setEventsData(events))
      .catch((error) => console.error('Error fetching events:', error));

    fetchTemplatesFromBackend()
      .then((templates) => setTemplates(templates))
      .catch((error) => console.error('Error fetching templates:', error));
  }, []);
  */

  const validateHTML = (htmlContent) => {
    // Sanitize the HTML content to prevent XSS
    const sanitizedHTML = DOMPurify.sanitize(htmlContent);

    // Check for the presence of required elements
    const headerPresent = /<header>/i.test(sanitizedHTML);
    const addressPresent = /<address>/i.test(sanitizedHTML);
    const privacyPolicyPresent = /<a href".*privacy policy.*">/i.test(sanitizedHTML);
    const unsubscribePlaceholderPresent = /{{{unsubscribe}}}/.test(sanitizedHTML);
    const unsubscribeLinkWorks = /<a[^>]*href=".*?">.*?<\/a>/i.test(sanitizedHTML);

    const isValid = headerPresent && addressPresent && privacyPolicyPresent && unsubscribePlaceholderPresent && unsubscribeLinkWorks;

    return {
      isValid,
      headerPresent,
      addressPresent,
      privacyPolicyPresent,
      unsubscribePlaceholderPresent,
      unsubscribeLinkWorks,
    };
  };

  // Function to handle file upload and validation

  const handleFileUpload = (file) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const htmlContent = e.target.result;
        const validationResults = validateHTML(htmlContent);

        // Set the validation result in state
        setValidationResult(validationResults);
      };

      reader.readAsText(file);
    }
  };

  // Function to handle file upload and validation

  const handleAddTemplate = () => {
    const newTemplateName = prompt("Enter the template name: Date-Company-Notes"); // Prompt the user for the template name
    if (newTemplateName) {
      // Add the new template to the list of templates
      setTemplates([...templates, newTemplateName]);
    }
  };

  const handleDeleteTemplate = (templateId) => {
    // Remove the template with the specified ID
    const updatedTemplates = templates.filter((template, index) => index !== templateId);
    setTemplates(updatedTemplates);
  };

  // Function to show the confirmation dialog for template deletion
  const showDeleteConfirmation = (index) => {
    setTemplateToDelete(index);
    setShowConfirmation(true);
  };

  // Function to cancel the template deletion
  const cancelDelete = () => {
    setTemplateToDelete(-1);
    setShowConfirmation(false);
  };

  // Function to confirm and delete the template
  const confirmDelete = () => {
    if (templateToDelete >= 0) {
      const updatedTemplates = templates.filter((_, index) => index !== templateToDelete);
      setTemplates(updatedTemplates);
      setTemplateToDelete(-1);
      setShowConfirmation(false);
    }
  };

  const eventsData = [
    { label: 'A Scheduled Event', timestamp: 1672226820, sent: true },
    { label: 'A Scheduled Event 2', timestamp: 1672314660, sent: false },
    { label: 'A Scheduled Event 3', timestamp: 1672410480, sent: false }
  ];

  return (
    <div id="adminContainer">
      <h1>Admin Page</h1>


      {showAdminPage ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <GridContainer>
            <Section
              name="client"
              title="Client Creation"
            >
              <CreationForm
                method="POST"
                action="/api/clients/"
                onSuccess={
                  body => {
                    alert(`Client “${body.name}” created successfully!`);
                    window.location.reload();
                  }
                }
              >
                <label>Name</label><br /><br />
                <input name="name" placeholder={"Client Name"} required />
                <button>Create</button>
              </CreationForm>
            </Section>

            <Section
              name="domain"
              title="Domain Creation"
            >
              <CreationForm
                method="POST"
                action="/api/domains/"
                onSuccess={
                  body => {
                    alert(`Domain “${body.alias}@${body.name}” created successfully!`);
                    window.location.reload();
                  }
                }
                onFailure={
                  () => {
                    alert(`There was an error trying to create your domain. Please ensure that the domain name and alias are valid. For example, to send from “alias@example.com”, the domain name should be “example.com” and the alias should be “alias”.`);
                  }
                }
              >
                <label>Name</label><br /><br />
                <input name="name" placeholder={"Domain Name"} required /><br /><br />
                <label>Alias</label><br /><br />
                <input name="alias" placeholder={"Domain Alias"} required /><br /><br />
                <label>Choose a client: </label>
                <ClientSelect clients={clients} />
                <br /><br />
                <button>Create</button>
              </CreationForm>
            </Section>
            <hr style={{ width: "100%", marginBlock: "2em" }} />
            <Section
              name="emaillist"
              title="Upload Email List"
            >
              <CreationForm
                method="POST"
                action="/api/lists/"
                onSuccess={
                  body => {
                    const { name } = body;
                    alert(`Mailing list “${name}” created successfully!`);
                    setUploadMailingListMessage(`Uploaded “${name}”`);
                  }
                }
                onFailure={
                  () => {
                    alert("The mailing list is in the wrong format. Please ensure that it has “email_address”, “first_name”, and “last_name” columns.")
                  }
                }
              >
                <label>Name</label><br /><br />
                <input name="name" placeholder={"Email List Name"} required /><br /><br />
                <label>Choose a client: </label>
                <ClientSelect clients={clients} />
                <br /><br />
                <label>Choose a CSV file:</label><br /><br />
                <input name="file" type="file" accept="text/csv" required /><br /><br />
                <button>Upload</button>
              </CreationForm>
              <p>
                {uploadMailingListMessage && (
                  <>
                    <FontAwesomeIcon icon={faCheck} />{" "}
                    <span>{uploadMailingListMessage}</span>
                  </>
                )}
              </p>
            </Section>
            <Section
              name="updateMailingList"
              title="Update Email List"
            >
              <ManagementForm>
                <label>Choose a client: </label>
                <ClientSelect
                  clients={clients}
                  onChange={
                    e => {
                      setUpdateMailingListClientId(e.target.value);
                    }
                  }
                />
                <br /><br />
                <label>Choose a list: </label>
                <ClientResourceSelect
                  name="mailingListId"
                  noun="list"
                  clientId={updateMailingListClientId}
                />
                <br /><br />
                <label>Choose a new CSV file:</label><br /><br />
                <input name="file" type="file" accept="text/csv" required /><br /><br />
                <div style={{ textAlign: "start", maxWidth: "20em" }}>
                  <em>Before updating the list, please ensure that the uploaded file has been approved and validated.</em>
                </div><br />
                <button>Update</button>
              </ManagementForm>
            </Section>
            <Section
              name="deleteMailingList"
              title="Delete Email List"
            >
              <DeletionForm
                noun="list"
                name="mailingListId"
                onSuccess={body => {
                  alert(`Mailing list “${body.name}” deleted successfully.`);
                  window.location.reload();
                }}
                onFailure={() => {
                  alert("The mailing list could not be deleted because it is associated with a scheduled event. Please delete the event before deleting the mailing list.")
                }}
              >
                <label>Choose a client: </label>
                <ClientSelect
                  clients={clients}
                  onChange={
                    e => {
                      setDeleteMailingListClientId(e.target.value);
                    }
                  }
                />
                <br /><br />
                <label>Choose a list: </label>
                <ClientResourceSelect
                  name="mailingListId"
                  noun="list"
                  clientId={deleteMailingListClientId}
                />
                <br /><br />
                <button>Delete</button>
              </DeletionForm>
            </Section>
            <Section
              name="deleteEmailTemplate"
              title="Delete Email Template"
            >
              <DeletionForm
                noun="template"
                name="emailTemplateId"
                onSuccess={body => {
                  alert(`Email template “${body.name}” deleted successfully.`);
                  window.location.reload();
                }}
                onFailure={() => {
                  alert("The email template could not be deleted because it is associated with a scheduled event. Please delete the event before deleting the email template.")
                }}
              >
                <label>Choose a client: </label>
                <ClientSelect
                  clients={clients}
                  onChange={
                    e => {
                      setDeleteEmailTemplateClientId(e.target.value);
                    }
                  }
                />
                <br /><br />
                <label>Choose a template: </label>
                <ClientResourceSelect
                  name="emailTemplateId"
                  noun="template"
                  clientId={deleteEmailTemplateClientId}
                />
                <br /><br />
                <button>Delete</button>
              </DeletionForm>
            </Section>
          </GridContainer>
        </div>
      ) : <p>You are currently unauthorized to view this page.</p>}
    </div>

  )
  };

export default AdminPage;
