import './Styles/CalendarPage.css';
import { getStealthHeaders } from "../utils/getStealthHeaders";
import React, { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Modal from "react-modal";

import ClientUpdateSelect from "../Components/FormComponents/ClientUpdateSelect";
import ClientResourceUpdateSelect from "../Components/FormComponents/ClientResourceUpdateSelect";

const localizer = momentLocalizer(moment);

function toIsoString(date) {
  var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
          return (num < 10 ? '0' : '') + num;
      };

  return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60);
}

const CustomToolbar = ({ label, onView, views, onNavigate }) => {
  const { selectedEvent, setSelectedEvent, selectedModal, setSelectedModal, setEventsAreUnknown } = useContext(CalendarPageContext);

  const selectHandler = async e => {
    const nextModal = e.target.value;
    if (nextModal === "delete") {
      if (window.confirm("Are you sure you want to delete this event?")) {
        fetch(`/api/events/${selectedEvent.id}/`, {
          method: "DELETE",
          headers: await getStealthHeaders()
        }).then(body => {
          setSelectedEvent(null);
          setSelectedModal(null);
          setEventsAreUnknown(true);
        });
      }
    } else {
      setSelectedModal(nextModal);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 'auto', width: '100%', paddingLeft: '40px', paddingRight: '10px' }}>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <button
          style={{ height: '30px', width: '30px', borderRadius: '50%', textTransform: 'capitalize', fontSize: '1.2em', lineHeight: 'default', border: '1px solid rgba(0,0,0,0.3)', cursor: 'pointer' }}
          onClick={() => onNavigate('PREV')}
        >
          {'<'}
        </button>
        <button
          style={{ height: '30px', width: '30px', borderRadius: '50%', textTransform: 'capitalize', fontSize: '1.2em', lineHeight: 'default', border: '1px solid rgba(0,0,0,0.3)', cursor: 'pointer' }}
          onClick={() => onNavigate('NEXT')}
        >
          {'>'}
        </button>
        <h3>{label}</h3>
      </div>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        {views.map(view => (
          <button
            style={{ height: '30px', width: 'auto', borderRadius: '0.3em', textTransform: 'capitalize', background: 'none', fontSize: '0.8em', lineHeight: 'default', border: '1px solid rgba(0,0,0,0.3)', cursor: 'pointer' }}
            key={view}
            onClick={() => onView(view)}
          >
            {view}
          </button>
        ))}
        <select
          style={{ height: '30px', width: 'auto', paddingLeft: '5px', paddingRight: '5px', borderRadius: '0.3em', textTransform: 'capitalize', background: 'none', fontSize: '0.8em', lineHeight: 'default', border: '1px solid rgba(0,0,0,0.3)', cursor: 'pointer' }}
          value={selectedModal ?? ""}
          onChange={selectHandler}
          placeholder="Actions"
        >
          <option hidden>Actions</option>
          <option value="schedule">Schedule Event</option>
          <option value="modify" disabled={selectedEvent === null}>Modify Event</option>
          <option value="delete" disabled={selectedEvent === null} style={{ background: 'red' }}>Delete Event</option>
        </select>
      </div>
    </div>
  );
};

function ModifyEventModal({ isOpen }) {
  const {
    selectedEvent, setSelectedEvent,
    setSelectedModal,
    clients,
    clientId, setClientId,
    domainId, setDomainId,
    mailingListId, setMailingListId,
    emailTemplateId, setEmailTemplateId,
    sendTime, setSendTime,
    setEventsAreUnknown
  } = useContext(CalendarPageContext);

  const localSendTime = toIsoString(new Date(`${sendTime}Z`)).slice(0, -6);

  const onSubmit = async e => {
    e.preventDefault();
    const response = await fetch(`/api/events/${selectedEvent.id}/`, {
      method: "PATCH",
      headers: await getStealthHeaders(),
      body: new FormData(e.target)
    }).then(body => {
      setSelectedEvent(null);
      setSelectedModal(null);
      setEventsAreUnknown(true);
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          zIndex: 5
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: "2em",
          zIndex: 100
        }
      }}
      appElement={document.querySelector("div")}
    >
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '25vw', borderRadius: '0.3em', background: 'white', zIndex: '11' }}>
          <button onClick={() => { setSelectedModal(null) }} style={{ background: 'white', border: 'none', position: 'absolute', right: '10px', top: '10px', fontSize: '2em', lineHeight: '0px', cursor: 'pointer', fontWeight: 'bold' }}>x</button>
          <h1>Modify An Event</h1>
          <form
            style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '20px', width: '80%', fontSize: '1.3em', borderRadius: '0.3em', textAlign: 'center' }}
            onSubmit={onSubmit}
          >
            <ClientUpdateSelect
              clients={clients}
              value={clientId}
              onChange={e => {
                setClientId(e.target.value);
              }}
            />
            <ClientResourceUpdateSelect
              name="domainId"
              noun="domain"
              clientId={clientId}
              value={domainId}
              onChange={e => {
                setDomainId(e.target.value);
              }}
            />
            <ClientResourceUpdateSelect
              name="mailingListId"
              noun="list"
              clientId={clientId}
              value={mailingListId}
              onChange={e => {
                setMailingListId(e.target.value);
              }}
            />
            <ClientResourceUpdateSelect
              name="emailTemplateId"
              noun="template"
              clientId={clientId}
              value={emailTemplateId}
              onChange={e => {
                setEmailTemplateId(e.target.value);
              }}
            />
            <input
              name="localSendTime"
              type="datetime-local"
              value={localSendTime}
              onChange={
                e => {
                  try {
                    setSendTime(new Date(e.target.value).toISOString().slice(0, -1));
                  } catch (e) {
                    setSendTime("");
                  }
                }
              }
            />
            <input name="sendTime" type="text" value={sendTime} hidden />
            <button style={{ fontSize: 'inherit', borderRadius: 'inherit', border: '1px solid rgba(0,0,0,0.3)', backgroundColor: 'rgba(0,0,0,0.05)', cursor: 'pointer' }}>Modify Event</button>
          </form>
        </div>
    </Modal>
  );
}

// TODO: create popups for scheduling, modifying, and deleting events

const CalendarPageContext = createContext();

function CalendarPage() {
  const [events, setEvents] = useState([]);
  const [eventsAreUnknown, setEventsAreUnknown] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [domainId, setDomainId] = useState(null);
  const [mailingListId, setMailingListId] = useState(null);
  const [emailTemplateId, setEmailTemplateId] = useState(null);
  const [sendTime, setSendTime] = useState("");

  useEffect(() => {
    (async () => {
      if (eventsAreUnknown) {
        const nextClients = await fetch("/api/clients/", {
          headers: await getStealthHeaders()
        }).then(response => response.json()).catch(() => []);
        setClients(nextClients);
        const nextEvents = await fetch("/api/events/", {
          headers: await getStealthHeaders()
        })
          .then(response => response.json())
          .then(body => body.map(event => {
            const startDate = new Date(`${event["send_time"]}Z`);
            return {
              id: event["id"],
              title: event["title"],
              start: startDate,
              end: startDate,
              clientId: event["client_id"],
              domainId: event["domain_id"],
              mailingListId: event["mailing_list_id"],
              emailTemplateId: event["email_template_id"],
            };
          }));
        setEvents(nextEvents);
        setEventsAreUnknown(false);
      }
    })();
  }, [eventsAreUnknown]);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setClientId(event.clientId);
    setDomainId(event.domainId);
    setMailingListId(event.mailingListId);
    setEmailTemplateId(event.emailTemplateId);
    setSendTime(event.start.toISOString().slice(0, -1));
  }

  const handleBackgroundClick = () => {
    if (selectedEvent) {
      setSelectedEvent(null);
      setClientId(null);
      setDomainId(null);
      setMailingListId(null);
      setEmailTemplateId(null);
      setSendTime(null)
    }
  };

  const value = {
    events, setEvents,
    eventsAreUnknown, setEventsAreUnknown,
    selectedEvent, setSelectedEvent,
    selectedModal, setSelectedModal,
    clients, setClients,
    clientId, setClientId,
    domainId, setDomainId,
    mailingListId, setMailingListId,
    emailTemplateId, setEmailTemplateId,
    sendTime, setSendTime
  };

  return (
    <CalendarPageContext.Provider value={value}>
      <div style={{ height: '100vh', position: 'relative' }}>
        {/*popup*/}
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          const views={['month', 'week', 'day']}
          components={{
            toolbar: CustomToolbar
          }}
          selectable
          onSelectEvent={handleEventClick}
          onSelectSlot={handleBackgroundClick}
        />
      </div>
      <ModifyEventModal isOpen={selectedModal === "modify"} />
    </CalendarPageContext.Provider>
  );
};

export default CalendarPage;
