import React, { createContext, useContext, useState } from 'react';

// Create a context
const EventContext = createContext();

// Create a provider component to manage events state
export function EventProvider({ children }) {
  const [events, setEvents] = useState([]);

  // Function to add a new event
  const addEvent = (newEvent) => {
    setEvents([...events, newEvent]);
  };

  // Function to delete an event
  const deleteEvent = (eventToDelete) => {
    const updatedEvents = events.filter((event) => event !== eventToDelete);
    setEvents(updatedEvents);
  };

  return (
    <EventContext.Provider value={{ events, addEvent, deleteEvent }}>
      {children}
    </EventContext.Provider>
  );
}

// Custom hook to access events and functions
export function useEventContext() {
  return useContext(EventContext);
}

export default useEventContext;